export interface LLMQuery {
    content: string;
    mode: string;
}

export interface LLMResponse extends Response {
    response: string;
}

const BACKEND_API: string | undefined = process.env.REACT_APP_API_URL;

export async function handleQuery(query: LLMQuery): Promise<LLMResponse> {
    console.log(process.env);
    const response = await fetch("/query_mag_synthesis",
        {
            method: "POST",
            headers: {
                "content-type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(query),
        }
    );
    if (!response.ok) {
        return Promise.reject(new Error(`request failed with reason "${response.text}"`));
    }
    const data: LLMResponse = await response.json();
    return data;
}
