import React, { useState } from "react";
import {
    Box,
    Container,
    Paper,
    TextField,
    IconButton,
    Typography,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

import { 
    LLMQuery,
    handleQuery 
} from "../../utils/handleQuery";

import './ChatPage.css'

type Message = {
    sender: string;
    content: React.ReactNode;
}


const ChatPage: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [inputText, setInputText] = useState<string>("");
    const [mode, setMode] = useState<string>("predict");

    const handleModeChange = (event: any) => {
        setMode(event.target.value as string);
    };

    const handleSendMessage = () => {
        if (inputText.trim()) {
            const userMessage: Message = { sender: "user", content: inputText }
            setMessages((prevMessages) => [...prevMessages, userMessage]);
            setInputText("");
        }
        try {
            const query: LLMQuery = {
                content: inputText.trim(),
                mode: mode,
            };
            handleQuery(query)
                .then((response) => {
                    const content = response.response;
                    var container = <div>
                            <span className="llm-response" >
                                {content}
                            </span>
                        </div>;
                    const botResponse: Message = { sender: "bot", content: container };
                    setMessages((prevMessages) => [...prevMessages, botResponse]);
                });
        } catch (error) {
            const errStr = `${error}`;
            const botResponse: Message = { sender: "bot", content: errStr };
            setMessages((prevMessages) => [...prevMessages, botResponse]);
        }
    };

    return (
        <Box display="flex" flexDirection="column" height="100vh">
            <Box
                component="header"
                bgcolor="primary.dark"
                color="white"
                p={2}
                textAlign="center"
                fontWeight="bold"
            >
                AI Lab Assistant
            </Box>

            <Container component="main" style={{ flexGrow: 1, overflowY: "auto", paddingTop: "16px" }}>
                <Paper style={{ padding: "16px", backgroundColor: "#f7f7f7", height: "70vh", overflowY: "auto" }}>
                    {messages.map((msg, index) => (
                        <Typography
                            key={index}
                            style={{
                                margin: "8px 0",
                                textAlign: msg.sender === "user" ? "right" : "left",
                                backgroundColor: msg.sender === "user" ? "#e0f7fa" : "#fff",
                                padding: "10px",
                                borderRadius: "8px",
                                maxWidth: "100%",
                                marginLeft: msg.sender === "user" ? "auto" : "0",
                            }}
                        >
                            {msg.content}
                        </Typography>
                    ))}
                </Paper>
                <Box display="flex" justifyContent="left" alignItems="left" p={2} bgcolor="#fff">
                    <Box display="flex" justifyContent="left" alignSelf="left" p={2} bgcolor="#fff">
                        <FormControl variant="outlined" style={{ minWidth: 200 }}>
                            <InputLabel id="mode-select-label">Select Mode</InputLabel>
                            <Select
                                labelId="mode-select-label"
                                id="mode-select"
                                value={mode}
                                onChange={handleModeChange}
                                label="Select "
                            >
                                <MenuItem value="predict">Synthesis from properies</MenuItem>
                                <MenuItem value="adjust">Synthesis from material and properties</MenuItem>
                                <MenuItem value="describe">Properties from synthesis</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box
                        component="main"
                        p={2}
                        bgcolor="#fff"
                        display="flex"
                        alignSelf="right"
                        alignItems="right"
                        width="100%"
                    >
                        <TextField
                            variant="outlined"
                            fullWidth
                            placeholder="Type your message here..."
                            value={inputText}
                            onChange={(e) => setInputText(e.target.value)}
                            onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
                        />
                        <IconButton color="primary" onClick={handleSendMessage}>
                            <SendIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default ChatPage;